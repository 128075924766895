import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { Image, Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router";
import homeIcon from "../Source/image/home-icon.png";
import "./index.css";
const { Header, Content, Footer } = Layout;
const Layouts = (props) => {
  const [current, setCurrent] = useState("home");
  const goRoutes = useNavigate();
  const {pathname} = useLocation();
  const { menus } = props;

  const canvasRef = useRef(null);
  useEffect(() => {
    const url = pathname.split("/");
    if (url[1]) {
      setCurrent(url[1]);
    }
    // 绘制动画
    // drawStars();
    // window.addEventListener(
    //   "resize",
    //   () => {
    //     setTimeout(() => {
    //       //   drawStars();
    //     }, 0);
    //   },
    //   false
    // );
  }, [pathname]);
  const drawStars = () => {
    var c = canvasRef.current;
    var ctx = c.getContext("2d"),
      stars = [],
      initialStars = 20,
      n = initialStars,
      incrementStars = 5,
      maxSize = 5,
      mouse = false,
      i;
    c.width = window.innerWidth;
    c.height = window.innerHeight;
    ctx.fillStyle = "#fff";

    function init() {
      for (i = 0; i < initialStars; i++) {
        stars.push({
          x: Math.floor(Math.random() * c.width),
          y: Math.floor(Math.random() * c.height),
          size: Math.random() * maxSize,
          speed: undefined,
        });
      }

      draw();
    }

    function draw() {
      ctx.clearRect(0, 0, c.width, c.height);
      stars.forEach((item) => {
        const { x, y, size } = item;
        ctx.beginPath();
        ctx.arc(x, y, size / 2, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
      });
    }

    function update() {
      stars.forEach((item, index) => {
        item.speed = item.size;
        if (mouse) {
          item.x += item.speed * 2;
        } else {
          item.x += item.speed;
        }
        if (item.x > c.width) {
          delete stars[index];
        }
      });
    }

    function createNewStars() {
      n += incrementStars;
      for (let i = 0; i < incrementStars; i++) {
        stars.push({
          x: -Math.floor(Math.random() * c.width),
          y: Math.floor(Math.random() * c.height),
          size: Math.random() * maxSize,
          speed: undefined,
        });
      }
    }

    function main() {
      draw();
      update();
      requestAnimationFrame(main);
    }

    setInterval(createNewStars, 500);

    c.addEventListener("mousedown", function () {
      mouse = true;
    });

    c.addEventListener("mouseup", function () {
      mouse = false;
    });

    init();
    main();
  };
  const onClickMenu = ({ key }) => {
    setCurrent(key);
    goRoutes(`/${key}`);
  };
  return (
    <Layout className="layout">
      <Header className="layout-header">
        <div className="logo">
          <img
            src={homeIcon}
            style={{ width: "120px", height: "60px" }}
            alt=""></img>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["home"]}
          items={menus}
          selectedKeys={[current]}
          onClick={onClickMenu}
        />
      </Header>
      <Content style={{ height: "100%" }}>
        {/* <canvas ref={canvasRef} className="canvas" height={'100%'}>
                </canvas> */}
        <div className="site-layout-content">{props.children}</div>
      </Content>
      <Footer className="footer">
        YZY@CopyRight <a href="mailto:19962392@qq.com">Contact Me</a>
        <br />
        备案号：
        <a href="https://beian.miit.gov.cn/" target="_blank">
          冀ICP备2021017449号-1
        </a>
      </Footer>
    </Layout>
  );
};
export default Layouts;
