import camelCase from 'lodash/camelCase'
import React from "react";
const context = require.context('./', false, /\.js$/)
const keys = context.keys().filter(path => path !== './index.js')

export default ()=>{
    const Stores = {}
    const stores = {}

    keys.forEach(path => {
        const Store = context(path).default
        const name = path.match(/\.\/(\w+)\.js/)[1]
        // 按照文件名生成自定义hooks
        const initStore = {
            ...new Store().initStore
        }

// 同步改变信息
        const reducers = {
            ...new Store().reducers
        }

// 异步改变信息
        const asyncEffects = {
            ...new Store().asyncEffects
        }

        const baseReducer = (store, nextStore) => {
            return { ...store, ...nextStore }
        }

        const Store2= () => {
            const [store, dispatch] = React.useReducer(baseReducer, initStore)
            // 同步任务
            const actions = Object.assign({},
                ...Object.entries(reducers).map(([funcName, func]) => ({
                    [funcName]: (params) => dispatch(func(params, store))
                })))
            // 异步任务
            const effects = Object.assign({},
                ...Object.entries(asyncEffects).map(([funcName, func]) => ({
                    [funcName]: async (params) => dispatch(await func(params, store))
                })))

            return {
                store,
                actions,
                effects
            }
        }
        // Stores[`${name}Store`] = Store
        stores[`${camelCase(name)}Store`] = Store2()
    })
    return {
        Stores, stores
    }
}

// export { Stores, stores }
