import { useRoutes } from "react-router";
import "antd/dist/antd.min.css";
import { RouterConfig } from "./router";
import Layouts from "./Components/Layouts";
import {
  SoundOutlined,
  HomeOutlined,
  PlaySquareOutlined,
  AlertOutlined,
  RedditOutlined
} from "@ant-design/icons";
import React from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import Store from "./Store";
import { MyContext } from "./Utils/contextHooks";
import { DndProvider } from "react-dnd";

function App() {
  const { stores } = Store();
  const menus = [
    {
      label: "首页",
      key: "home",
      icon: <HomeOutlined />,
    },
    {
      label: "音乐",
      key: "music",
      icon: <SoundOutlined />,
    },
    {
      label: "视频",
      key: "video",
      icon: <PlaySquareOutlined />,
    },
    {
      label: "布灵布灵",
      key: "bling",
      icon: <AlertOutlined />,
    },
    {
      label:'业哥好AI',
      key:'yai',
      icon:<RedditOutlined />
    }
  ];
  return (
    <MyContext.Provider value={{ ...stores }}>
      <DndProvider backend={HTML5Backend}>
        <Layouts menus={menus}>{useRoutes(RouterConfig)}</Layouts>
      </DndProvider>
    </MyContext.Provider>
  );
}

export default App;
