// 切换路由的Loading组件
import React from "react";
import './index.css'
import {Spin} from "antd";
const Loading:React.FC = props => {
    return <div className="loading-container">
        <div className="loading">
            <div className="left"></div>
            <div className="right"></div>
            <div className="top"></div>
            <div className="bottom"></div>
        </div>
    </div>
}
export default Loading