// 封装的请求函数
import axios from "axios";

// 响应拦截
const response= (response,error)=>{
    if(response){
        if(response.status===200) {
            return response.data
        }else{
            return {code:response.status,message:'获取信息失败'}
        }
    }else{
        return {code:'666666',message:error}
    }
}
// mock请求接口
export const requestMock = axios.create({
    baseURL:'/check',
    timeout:5000
})

// 进制数据接口
export const requestBD = axios.create({
    baseURL:'/api',
    timeout:5000
})

// 普通接口
export const request = axios.create({
    baseURL:'/',
    timeout:5000
})

// 疫情相关请求
export const requestDisease = axios.create({
    baseURL:'disease/',
    timeout:5000,
})

// 网易云音乐相关接口
export const requestMusic = axios.create({
    baseURL:'https://autumnfish.cn/',
    timeout:5000
})

request.interceptors.response.use(response)
requestBD.interceptors.response.use(response)
requestDisease.interceptors.response.use(response)
requestMusic.interceptors.response.use(response)
requestMock.interceptors.response.use(response)