// 这里是react的路由配置
import { lazy } from "react";
import { Navigate } from "react-router";
// 懒加载组件
const LazyLoad = (name, type) => {
  let Component = lazy(() => import(`../src/Pages/${name}`));
  return <Component />;
};

export const RouterConfig = [
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
  {
    path: "/home",
    element: LazyLoad("HomePage"),
  },
  {
    path: "/music",
    element: LazyLoad("Music"),
  },
  {
    path: "/video",
    element: LazyLoad("Video"),
  },
  {
    path: "/bling",
    element: LazyLoad("Bling"),
  },
  {
    path:'yai',
    element:LazyLoad('Yai')
  },
  {
    path: "/test",
    element: LazyLoad("Test"),
  },
  {
    path: "*",
    element: LazyLoad("NotFound"),
  },
];
